<template>
<div>

    <v-snackbar v-model="snackbar" top :timeout="2000">
        {{ snackbar_msg }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>

    <div class="row mt-4">

        <div class="col-sm-12">
            <v-autocomplete outlined v-if="status" v-model="c_status" :items="status" item-text="text" item-value="value" label="สถานะ"></v-autocomplete>

        </div>

        <div class="col-sm-12">
            <v-textarea v-model="detail" outlined name="input-7-4" label="รายละเอียดความคืบหน้า"></v-textarea>
        </div>

        <div class="text-center col-sm-12">
            <v-btn depressed color="primary" @click="updateStatus()">
                บันทึก
            </v-btn>
        </div>

        <div class="col-sm-12" v-if="items">
            <v-card class="mb-5" outlined style="width:100%" color="blue lighten-5" v-for="item in items" :key="item.detail_id">
                <div class="pa-3" v-html="item.msg"></div>
                <div class="pa-3">{{item.created}}</div>

            </v-card>
        </div>
    </div>

</div>
</template>

<script>
export default {

    components: {

    },
    props: {
        item: null,
        register_id: null,
    },

    data: () => ({
        status: null,
        c_status: null,
        detail: null,

        items: null,
        snackbar: null,
        snackbar_msg: null,

    }),

    computed: {

    },

    watch: {
        item() {
                console.log(this.item)
            if (this.item) {
                this.c_status = this.item.c_status
            }
        },
        register_id() {
            this.getItems();
        }
    },

    methods: {

        updateStatus() {
            let $vm = this;

            this.snackbar_msg = null
            this.snackbar = null

            const formData = new FormData();
            formData.append("c_status", $vm.c_status);
            formData.append("detail", $vm.detail);
            formData.append("register_id", $vm.register_id);

            var url = "register/setStatus?time=" + Math.random();
            // console.log(url);
            this.$http
                .post(url, this.formData(formData))
                .then(function (res) {
                    console.log(res.data);

                    if (res.data.set_status == 'success') {
                        $vm.$emit('updateStatus', true)
                    }

                    $vm.snackbar_msg = 'อัปเดทสถานะเรียบร้อยแล้วค่ะ'
                    $vm.snackbar = true

                    if (res.data.set_detail == 'success') {
                        $vm.getItems()
                        $vm.detail = null
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getStatusList() {
            let url = "register/status";
            console.log(url)

            this.loading = true

            let $vm = this;
            this.$http
                .post(url, this.formData())
                .then(function (res) {
                    console.log(res.data);

                    $vm.status = Object.values(res.data.status)
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        getItems() {
            let url = "register/detail?register_id=" + this.register_id;
            console.log(url)

            this.loading = true

            let $vm = this;
            this.$http
                .post(url, this.formData())
                .then(function (res) {
                    console.log(res.data);

                    $vm.loading = false
                    $vm.items = res.data.items

                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    },
    created() {

        this.getItems();

        this.getStatusList();

        if (this.item) {
            this.c_status = this.item.c_status
        }

    },

};
</script>
